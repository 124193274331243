<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="New">
        <Assigment_Vehicle></Assigment_Vehicle>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Assigment_Vehicle from "./_tab/table-assigment.vue";
export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Assigment_Vehicle,
  },
  data() {
    return {
      title: "DO Re-assignment",
    };
  },
};
</script>
