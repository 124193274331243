<template>
  <div>
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Warehouse</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedWarehouse"
            :options="optionWarehouse"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLableWarehouse"
            @select="getTransportPlan"
          />
        </div>
      </div>
    </div>
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Delivery Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input
            class="w-full"
            v-model="date"
            type="date"
            :min="minDate()"
            v-validate="'required'"
          />
        </div>
      </div>
    </div>
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Shipment Code</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedTransportPlan"
            :options="optionTransportPlan"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            label="shipment_number"
            track-by="id"
            placeholder="Type to search"
            :searchable="true"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-row sm:w-4/5 w-full space-x-1 ml-4">
          <vs-button
            @click="onClickBtnFilter"
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-search"
            title="Search"
          >
            Filter</vs-button
          >
          <vs-button
            @click="onClickBtnClearFilter"
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-trash-2"
            title="Clear"
          >
            Clear</vs-button
          >
        </div>
      </div>
    </div>

    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th
                    ><vs-checkbox
                      v-model="selectAll"
                      @input="handleSelectAll"
                    />ALL</vs-th
                  > -->
        <vs-th>Select</vs-th>
        <vs-th sort-key="code">Customer Data</vs-th>
        <vs-th sort-key="name">Delivery Order</vs-th>
        <vs-th sort-key="name">DO Status</vs-th>
        <vs-th sort-key="name">Operation Zone</vs-th>
        <vs-th sort-key="name">Current Vehicle</vs-th>
        <vs-th sort-key="name">New Vehicle</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-checkbox
                v-model="cartVehicleAssigment"
                :vs-value="tr"
                color="success"
                @input="handleClickCheckBox"
              />
            </div>
          </vs-td>

          <vs-td>
            <ul>
              <li>
                {{ data[indextr].customer_name }}
              </li>
              <li>
                {{ data[indextr].customer_code }}
              </li>
            </ul>
          </vs-td>
          <vs-td>
            {{ data[indextr].delivery_order_code }}
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
          <vs-td>
            {{ data[indextr].zone_code }}
          </vs-td>
          <vs-td>
            {{ data[indextr].vehicle_name }}
          </vs-td>
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                size="medium"
                color="success"
                icon-pack="feather"
                icon="icon-plus"
                title="Add Customer"
                @click="
                  openModal1(
                    data[indextr].indextr,
                    data[indextr].transport_plan_id
                  )
                "
              />
            </div>
          </vs-td>
          <!-- detail transport pickup -->
          <template slot="expand">
            <div class="vx-row" :style="{ overflow: 'auto' }">
              <div class="vx-col">
                <vs-table
                  :style="{ width: '100%' }"
                  :sst="false"
                  :data="tr.vehicle"
                  :max-items="tr.vehicle.length"
                  :total="tr.vehicle.total"
                  :searchable="false"
                >
                  <template slot="thead">
                    <vs-th>Select</vs-th>
                    <vs-th>Vehicle Number</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="ts"
                      :key="indextrVehicle"
                      v-for="(ts, indextrVehicle) in data"
                    >
                      <vs-td class="whitespace-no-wrap">
                        <feather-icon
                          title="Delete"
                          icon="TrashIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="ml-2"
                          @click.stop="
                            deleteVehiclePickup(ts, indextrVehicle, tr.indextr)
                          "
                        />
                      </vs-td>
                      <vs-td>
                        <b>{{ ts.name }}</b>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </template>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
    <vs-popup :active.sync="showModal" title="Select Vehicle" width="70%">
      <vs-table
        :style="{ width: '100%' }"
        :sst="false"
        :data="transportPlan"
        :total="transportPlan.total"
        :searchable="false"
      >
        <template slot="thead">
          <vs-th>Select</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Shipment Number</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="ts" :key="indextr" v-for="(ts, indextr) in data">
            <vs-checkbox
              v-model="cartVehicles"
              :vs-value="ts"
              color="success"
              @input="handleClickCheckBox2()"
            />
            <vs-td>
              <b>{{ ts.vehicle_number }}</b>
            </vs-td>
            <vs-td>
              <b>{{ ts.shipment_number }}</b>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="vx-row mt-4">
        <div class="vx-col sm:w-6/12 w-full text-center">
          <vs-button @click="closeModal1()" color="primary" class="w-32">
            Save
          </vs-button>
        </div>
        <div class="vx-col sm:w-6/12 w-full text-center">
          <vs-button @click="closeModal2()" color="primary" class="w-32">
            Close
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <div class="vx-row">
      <div class="vx-col sm:w-6/12 w-full">
        <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
          >Assign</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},

  mounted() {
    this.getWarehouse();
  },

  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      table2: this.tableDefaultState(),
      transportPlan: [],
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      cartVehicleAssigment: [],
      cartVehicles: [],
      IDItem: [],
      warehouseID: null,
      date: moment().format("YYYY-MM-DD"),
      optionWarehouse: [],
      selectedWarehouse: {},
      optionTransportPlan: [],
      selectedTransportPlan: {},
      showModal: false,
      modalData: [],
      appendVehicles: [],
      indextrPopup: null,
      dateTp: "",
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    openModal1(indextr, id) {
      this.getVehicle(id);

      console.log("PPPPPP", this.transportPlan);
      if (this.transportPlan.length > 0) {
        this.indextrPopup = null;
        this.indextrPopup = indextr;
        this.modalData = this.transportPlan; // Set the data for the modal table

        console.log("indextr", this.indextrPopup);
        this.showModal = true;
      } else {
        this.showModal = false;
        this.transportPlan = [];
      }
    },
    closeModal1() {
      this.showModal = false;

      if (this.cartVehicles != []) {
        for (let index = 0; index < this.cartVehicles.length; index++) {
          this.appendVehicles.push({
            id: this.cartVehicles[index].id,
            name: this.cartVehicles[index].vehicle_number,
            capacity_volume: this.cartVehicles[index].total_volume,
            capacity_weight: this.cartVehicles[index].total_weight,
          });
        }
        console.log("yyyye", this.table.data[this.indextrPopup]);
        this.table.data[this.indextrPopup]["vehicle"] = this.appendVehicles;
      }
      this.cartVehicles = [];
      this.appendVehicles = [];
      console.log("yyyyewww", this.table.data[this.indextrPopup]);
    },
    closeModal2() {
      this.showModal = false;
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/vehicle-reassigment/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            // date: this.date,
            warehouse_id: this.selectedWarehouse.id,
            transport_plan_id: this.selectedTransportPlan.id,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            if (this.table.data) {
              for (let index = 0; index < resp.data.records.length; index++) {
                this.table.data[index]["indextr"] = index;
                this.table.data[index]["vehicle"] = [];
              }
            }
            console.log("weee", this.table.data);

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleClickCheckBox() {
      console.log(">>>>iiii", this.cartVehicleAssigment);
      this.$emit("selected-items", this.cartVehicleAssigment);
    },
    handleClickCheckBox2() {
      console.log(">>>>iiii", this.cartVehicles);
      this.$emit("selected-items", this.cartVehicles);
    },
    handleSelectAll(id) {
      if (id == false) {
        this.exportID = [];
      } else {
        this.exportID = this.IDItem;
      }
      this.$emit("selected-items", this.IDItem);
    },
    onClickBtnFilter() {
      if (this.selectedWarehouse.id == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Warehouse",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      if (this.selectedTransportPlan.id == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Transport Plan",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      this.warehouseID = this.selectedWarehouse.id;

      this.getData();
    },
    onClickBtnClearFilter() {
      this.selectedWarehouse = { code: "", name: "" };
      this.selectedTransportPlan = {};
      this.date = moment().format("YYYY-MM-DD");
      // this.getData();
      this.table.data = [];
    },

    SubmitForm() {
      console.log(">>>>>ini submit", this.cartVehicleAssigment);
      if (this.cartVehicleAssigment.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      if (this.selectedTransportPlan.id == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Transport Plan",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      for (let index = 0; index < this.cartVehicleAssigment.length; index++) {
        if (this.cartVehicleAssigment[index].vehicle.length == 0) {
          this.$vs.notify({
            title: "Error",
            text:
              "Please select Vehicle On Code: " +
              this.cartVehicleAssigment[index].shipment_number,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return true;
        }
      }

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/vehicle-reassigment/re-assign", {
          transport_plan_id_was_reasigment: this.selectedTransportPlan.id,
          delivery_order_vehicle: this.cartVehicleAssigment,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            // this.getData();
            this.cartVehicleAssigment = [];
            this.selectedTransportPlan = {};
            this.selectedWarehouse = {};
            this.table.data = [];
            this.date = moment().format("YYYY-MM-DD");
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
              if (this.optionWarehouse.length > 0) {
                this.selectedWarehouse = {
                  id: this.$userLogin.warehouse_id,
                  code: this.$userLogin.warehouse_code,
                  name: this.$userLogin.warehouse_name,
                };
              } else {
                this.optionWarehouse = [];
                this.selectedWarehouse = {};
              }
            } else {
              this.optionWarehouse = [];
              this.selectedWarehouse = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableWarehouse({ code, name }) {
      return `${code} ${name}`;
    },

    getVehicle(id) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/vehicle-reassigment/vehicle", {
          params: {
            length: this.table2.length,
            page: this.table2.page,
            search: this.table2.search,
            order: this.table2.order,
            sort: this.table2.sort,
            transport_plan_id: id,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table2.total = resp.data.total_record;
            this.table2.totalPage = resp.data.total_page;
            this.table2.totalSearch = resp.data.total_record_search;
            this.table2.length = resp.data.total_record_per_page;
            this.transportPlan = resp.data.records;
            console.log("weee", this.transportPlan);

            this.setStartEnd();
            if (this.transportPlan == null) {
              this.$vs.notify({
                title: "Danger",
                text: "Vahicle was not found",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }

            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getTransportPlan() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/all-transport-plan", {
          params: {
            length: this.table2.length,
            page: this.table2.page,
            search: this.table2.search,
            order: this.table2.order,
            sort: this.table2.sort,
            warehouse_id: this.selectedWarehouse.id,
            date: this.date,
            is_assigned: true,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionTransportPlan = resp.data.records;

            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    minDate() {
      // Create a new Date object
      const date = new Date();

      // Get the time in GMT+7
      const gmt7Time = new Date(date.getTime() + 7 * 60 * 60 * 1000);

      // Extract the year, month, and date
      const year = gmt7Time.getUTCFullYear();
      const month = String(gmt7Time.getUTCMonth() + 1).padStart(2, "0");
      const day = String(gmt7Time.getUTCDate()).padStart(2, "0");

      // Extract the hours and minutes
      const hours = String(gmt7Time.getUTCHours()).padStart(2, "0");
      const minutes = String(gmt7Time.getUTCMinutes()).padStart(2, "0");

      // Format the date and time to a string suitable for the input type
      return `${year}-${month}-${day}`;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    //if select cartVehicles more than 1 then first data will be unselected
    cartVehicles: {
      handler: function (val) {
        if (val.length > 1) {
          this.cartVehicles.shift();
        }
      },
      deep: true,
    },
    selectedWarehouse: {
      handler: function (val) {
        if (val.id != null && this.date != null) {
          this.getTransportPlan();
        }
      },
      deep: true,
    },
  },
  computed: {
    // setPage: {
    //   get() {
    //     return 1;
    //   },
    //   set(val) {
    //     this.handleChangePage(val);
    //   },
    // },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style>
.vs-popup--background {
  opacity: 0.05;
}

.con-vs-popup .vs-popup {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 100;
  width: 60% !important;
  margin: 10px;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-animation: rebound 0.3s;
  animation: rebound 0.3s;
}
</style>
